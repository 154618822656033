import React, { useEffect, useState } from 'react';
import Footer from '../components/footer';
import Header from '../components/header/header';
import { useSelector } from 'react-redux';
import './styles.scss';

const privacyPolicy = () => {
  const [showEspContent, setShowEspContent] = useState(null);
  const { language } = useSelector((state) => state);

  useEffect(() => {
    if (language === 'eng') {
      setShowEspContent(false);
    } else if (language === 'esp') {
      setShowEspContent(true);
    }
  }, [language]);

  const policyDescEng = ` This privacy policy sets out how this website (hereafter "the Store") uses and protects any information that you give the Store while using this website. The Store is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement. The Store may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.`;
  const policyDescEsp = `Esta política de privacidad establece cómo este sitio web (en adelante, "la Tienda") 
 utiliza y protege cualquier información que usted proporcione a la Tienda mientras 
 utiliza este sitio web. La Tienda se compromete a garantizar que su privacidad esté 
 protegida. Si le solicitamos que proporcione cierta información mediante la cual 
 pueda ser identificado al utilizar este sitio web, puede estar seguro de que solo se 
 utilizará de acuerdo con esta declaración de privacidad. La Tienda puede cambiar 
 esta política de vez en cuando al actualizar esta página. Debe consultar esta página
 de vez en cuando para asegurarse de que está satisfecho con cualquier cambio.`;

  return (
    <div>
      <Header show={true} />
      <div className="privacy-policy-container dashboard-inner" style={{ marginTop: '46px' }}>
        <section className="privacy-section-top">
          <h1>{language === 'eng' ? 'Privacy and Cookie Policy' : 'Política de privacidad y cookies'}</h1>
          <p>{language === 'eng' ? policyDescEng : policyDescEsp}</p>
          <h3>{language === 'eng' ? 'What we collect' : 'Lo que recolectamos'}</h3>
          <p>
            {language === 'eng'
              ? 'We may collect the following information:'
              : 'Podemos recopilar la siguiente información:'}
          </p>
          <ul>
            <li>{language === 'eng' ? 'name' : 'nombre'}</li>
            <li>
              {language === 'eng'
                ? 'contact information including email address'
                : 'información de contacto, incluyendo el correo electrónico'}
            </li>
            <li>
              {language === 'eng'
                ? 'demographic information such as postcode, preferences and interests'
                : 'nformación demográfica como código postal, preferencias e intereses'}
            </li>
            <li>
              {language === 'eng'
                ? ' other information relevant to customer surveys and/or offers For the exhaustive list of cookies, we collect see the List of Cookies section.'
                : 'tra información relevante para encuestas de clientes y/o ofertas'}
            </li>
          </ul>
        </section>
        <section className={showEspContent ? 'hide' : 'privacy-policy-middle-section'}>
          <h3>What we do with the information we gathers</h3>
          <p>
            We require this information to understand your needs and provide you with a better service, and in
            particular for the following reasons:
          </p>
          <ul>
            <li>Internal record keeping.</li>
            <li>We may use the information to improve our products and services.</li>
            <li>
              We may periodically send promotional emails about new products, special offers or other information which
              we think you may find interesting using the email address which you have provided.
            </li>
            <li>
              From time to time, we may also use your information to contact you for market research purposes. We may
              contact you by email, phone, fax or mail. We may use the information to customize the website according to
              your interests.
            </li>
          </ul>
          <h3>Security</h3>
          <p>
            We are committed to ensuring that your information is secure. In order to prevent unauthorized access or
            disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and
            secure the information we collect online.
          </p>
        </section>
        <section className={!showEspContent ? 'hide' : 'privacy-policy-middle-section'}>
          <h3>Qué hacemos con la información que recopilamos</h3>
          <p>
            Requerimos esta información para comprender sus necesidades y brindarle un mejor servicio, y en particular
            por las siguientes razones
          </p>
          <ul>
            <li>Mantenimiento de registros internos.</li>
            <li>Podemos utilizar la información para mejorar nuestros productos y servicios.</li>
            <li>
              Podemos enviar periódicamente correos electrónicos promocionales sobre nuevos productos, ofertas
              especiales u otra información que creemos que puede resultar interesante utilizando la dirección de correo
              electrónico que nos ha proporcionado.
            </li>
            <li>
              De vez en cuando, también podemos utilizar su información para comunicarnos con usted para investigación
              de mercado. Podemos comunicarnos con usted por correo electrónico, teléfono, fax o correo. Podemos
              utilizar la información para personalizar el sitio web de acuerdo con sus intereses.
            </li>
          </ul>
          <h3>Seguridad</h3>
          <p>
            Estamos comprometidos a garantizar que su información esté segura. Para evitar el acceso o la divulgación no
            autorizados, hemos implementado procedimientos físicos, electrónicos y administrativos adecuados para
            proteger y asegurar la información que recopilamos en línea.
          </p>
        </section>
        <section className={showEspContent ? 'hide' : 'cookies-section'}>
          <h3>How we use cookies</h3>
          <p>
            A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree,
            the file is added and the cookie helps analyze web traffic or lets you know when you visit a particular
            site. Cookies allow web applications to respond to you as an individual. The web application can tailor its
            operations to your needs, likes and dislikes by gathering and remembering information about your
            preferences.
          </p>
          <p>
            We use traffic log cookies to identify which pages are being used. This helps us analyze data about web page
            traffic and improve our website in order to tailor it to customer needs. We only use this information for
            statistical analysis purposes and then the data is removed from the system.
          </p>
          <p>
            Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find
            useful and which you do not. A cookie in no way gives us access to your computer or any information about
            you, other than the data you choose to share with us. You can choose to accept or decline cookies. Most web
            browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if
            you prefer. This may prevent you from taking full advantage of the website.
          </p>
          <h3>Links to other websites</h3>
          <p>
            Our website may contain links to other websites of interest. However, once you have used these links to
            leave our site, you should note that we do not have any control over that other website. Therefore, we
            cannot be responsible for the protection and privacy of any information which you provide whilst visiting
            such sites and such sites are not governed by this privacy statement. You should exercise caution and look
            at the privacy statement applicable to the website in question.
          </p>
        </section>
        <section className={!showEspContent ? 'hide' : 'cookies-section'}>
          <h3>Como usamos las cookies</h3>
          <p>
            Una cookie es un pequeño archivo que solicita permiso para colocarse en el disco duro de su computadora. Una
            vez que acepta, se agrega el archivo y la cookie ayuda a analizar el tráfico web o le permite saber cuándo
            visita un sitio en particular. Las cookies permiten que las aplicaciones web le respondan como un individuo.
          </p>
          <p>
            La aplicación web puede adaptar sus operaciones a sus necesidades, gustos y disgustos al recopilar y
            recordar información sobre sus preferencias.
          </p>
          <p>
            En general, las cookies nos ayudan a brindarle un mejor sitio web, al permitirnos monitorear qué páginas
            encuentra útiles y cuáles no. Una cookie de ninguna manera nos da acceso a su computadora ni a ninguna
            información sobre usted, aparte de los datos que elija compartir con nosotros. Puede optar por aceptar o
            rechazar las cookies. La mayoría de los navegadores web aceptan automáticamente las cookies, pero
            normalmente puede modificar la configuración de su navegador para rechazar las cookies si lo prefiere. Esto
            puede impedirle aprovechar al máximo el sitio web.
          </p>
          <h3>Enlaces a otros sitios web</h3>
          <p>
            Nuestro sitio web puede contener enlaces a otros sitios web de interés. Sin embargo, una vez que haya
            utilizado estos enlaces para salir de nuestro sitio, debe tener en cuenta que no tenemos ningún control
            sobre ese otro sitio web. Por lo tanto, no podemos ser responsables de la protección y privacidad de
            cualquier información que usted proporcione mientras visita ciertos sitios y ciertos sitios no son regulados
            por esta declaración de privacidad. Debe tener cuidado y consultar la declaración de privacidad aplicable al
            sitio web en cuestión.
          </p>
        </section>

        <section className="personalinfo">
          <div className={showEspContent ? 'hide' : ''}>
            <p>You may choose to restrict the collection or use of your personal information in the following ways:</p>
            <ul>
              <li>
                whenever you are asked to fill in a form on the website, look for the box that you can click to indicate
                that you do not want the information to be used by anybody for direct marketing purposes
              </li>
              <li>
                if you have previously agreed to us using your personal information for direct marketing purposes, you
                may change your mind at any time by letting us know using our Contact Us information We will not sell,
                distribute or lease your personal information to third parties unless we have your permission or are
                required by law to do so. We may use your personal information to send you promotional information about
                third parties which we think you may find interesting if you tell us that you wish this to happen.
              </li>
            </ul>
            <p>
              You may request details of personal information which we hold about you under the Data Protection Act
              1998. A small fee will be payable. If you would like a copy of the information held on you, please email
              us this request using our Contact Us information.
            </p>
            <p>
              If you believe that any information, we are holding on you is incorrect or incomplete, please write to or
              email us as soon as possible, at the above address. We will promptly correct any information found to be
              incorrect.
            </p>
          </div>

          <div className={!showEspContent ? 'hide' : ''}>
            <p>
              Puede optar por restringir la recopilación o el uso de su información personal de las siguientes maneras:
            </p>
            <ul>
              <li>
                siempre que se le solicite que complete un formulario en el sitio web, busque el cuadro en el que puede
                hacer clic para indicar que no desea que nadie utilice la información con fines de marketing directo
              </li>
              <li>
                Si previamente ha aceptado que usemos su información personal con fines de marketing directo, puede
                cambiar de opinión en cualquier momento utilizando nuestra información de contacto.
              </li>
            </ul>
            <p>
              No venderemos, distribuiremos ni cederemos su información personal a terceras partes a menos que tengamos
              su permiso o la ley nos exija hacerlo. Podemos utilizar su información personal para enviarle información
              promocional sobre terceras partes que creemos que puede resultarle interesante si nos dice que desea que
              esto suceda.Puede solicitar detalles de la información personal que tenemos sobre usted bajo la Ley de
              Protección de Datos de 1998. Se pagará una pequeña tarifa. Si desea una copia de la información que
              tenemos sobre usted, envíenos un correo electrónico con esta solicitud utilizando nuestra información de
              contacto.
            </p>
            <p>
              Si cree que la información que tenemos sobre usted es incorrecta o está incompleta, escríbanos o envíenos
              un correo electrónico lo antes posible a la dirección anterior. Corregiremos de inmediato cualquier
              información que resulte incorrecta.
            </p>
          </div>

          <h3>{language === 'eng' ? 'List of cookies we collect' : 'Lista de cookies que recopilamos'}</h3>
          <table>
            <tr className="cookies-table-row">
              <th>{language === 'eng' ? 'COOKIE Name' : 'Nombre de la COOKIE'}</th>
              <th>{language === 'eng' ? 'COOKIE Description' : 'Descripción de la COOKIE'}</th>
            </tr>
            <tr>
              <td>FORM_KEY</td>
              <td>
                {language === 'eng'
                  ? 'Stores randomly generated key used to prevent forged requests.'
                  : 'Almacena la clave generada aleatoriamente que se utiliza para evitar solicitudes falsificadas.'}
              </td>
            </tr>
            <tr>
              <td>PHPSESSID</td>
              <td>{language === 'eng' ? 'Your session ID on the server.' : 'Su ID de sesión en el servidor.'}</td>
            </tr>
            <tr>
              <td>GUEST-VIEW</td>
              <td>
                {language === 'eng'
                  ? 'Allows guests to view and edit their orders.'
                  : 'Permite a las invitadas ver y editar sus pedidos.'}
              </td>
            </tr>
            <tr>
              <td>PERSISTENT_SHOPPING_CART</td>
              <td>
                {language === 'eng'
                  ? 'A link to information about your cart and viewing history, if you have asked for this.'
                  : 'Un enlace a información sobre su carrito e historial de visualización, si lo ha solicitado.'}
              </td>
            </tr>
            <tr>
              <td>STF</td>
              <td>
                {language === 'eng'
                  ? 'Information on products you have emailed to friends.'
                  : 'Información sobre productos que ha enviado por correo electrónico a sus amigos.'}
              </td>
            </tr>
            <tr>
              <td>STORE</td>
              <td>
                {language === 'eng'
                  ? 'The store view or language you have selected.'
                  : 'La vista de la tienda o el idioma que ha seleccionado.'}
              </td>
            </tr>
            <tr>
              <td>USER_ALLOWED_SAVE_COOKIE</td>
              <td>
                {language === 'eng'
                  ? 'Allows guests to view and edit their orders.Indicates whether a customer allowed to use cookies.'
                  : 'Permite a los invitados ver y editar sus pedidos. Indica si un cliente está autorizado a utilizar cookies.'}
              </td>
            </tr>
            <tr>
              <td>MAGE-CACHE-SESSID</td>
              <td>
                {language === 'eng'
                  ? 'Facilitates caching of content on the browser to make pages load faster.'
                  : 'Facilita el almacenamiento en caché de contenido en el navegador para que las páginas se carguen más rápido.'}
              </td>
            </tr>
            <tr>
              <td>MAGE-CACHE-STORAGE</td>
              <td>
                {language === 'eng'
                  ? 'Facilitates caching of content on the browser to make pages load faster.'
                  : 'Facilita el almacenamiento en caché de contenido en el navegador para que las páginas se carguen más rápido.'}
              </td>
            </tr>
            <tr>
              <td>MAGE-CACHE-STORAGE-SECTION-INVALIDATION</td>
              <td>
                {language === 'eng'
                  ? 'Facilitates caching of content on the browser to make pages load faster.'
                  : 'Facilita el almacenamiento en caché de contenido en el navegador para que las páginas se carguen más rápido.'}
              </td>
            </tr>
            <tr>
              <td>MAGE-CACHE-TIMEOUT</td>
              <td>
                {language === 'eng'
                  ? 'Facilitates caching of content on the browser to make pages load faster.'
                  : 'Facilita el almacenamiento en caché de contenido en el navegador para que las páginas se carguen más rápido.'}
              </td>
            </tr>
            <tr>
              <td>SECTION-DATA-IDS</td>
              <td>
                {language === 'eng'
                  ? 'Facilitates caching of content on the browser to make pages load faster.'
                  : 'Facilita el almacenamiento en caché de contenido en el navegador para que las páginas se carguen más rápido.'}
              </td>
            </tr>
            <tr>
              <td>PRIVATE_CONTENT_VERSION</td>
              <td>
                {language === 'eng'
                  ? 'Facilitates caching of content on the browser to make pages load faster.'
                  : 'Facilita el almacenamiento en caché de contenido en el navegador para que las páginas se carguen más rápido.'}
              </td>
            </tr>
            <tr>
              <td>X-MAGENTO-VARY</td>
              <td>
                {language === 'eng'
                  ? 'Facilitates caching of content on the browser to make pages load faster.'
                  : 'Facilita el almacenamiento en caché de contenido en el navegador para que las páginas se carguen más rápido.'}
              </td>
            </tr>
            <tr>
              <td>MAGE-TRANSLATION-FILE-VERSION</td>
              <td>
                {language === 'eng'
                  ? 'Facilitates translation of content to other languages.'
                  : 'Facilita la traducción de contenido a otros idiomas'}
              </td>
            </tr>
            <tr>
              <td>MAGE-TRANSLATION-STORAGE</td>
              <td>
                {language === 'eng'
                  ? 'Facilitates translation of content to other languages.'
                  : 'Facilita la traducción de contenido a otros idiomas'}
              </td>
            </tr>
          </table>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default privacyPolicy;
